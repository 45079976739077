import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { from } from 'linq-to-typescript';
import * as moment from 'moment';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt, SpartaxEinkunftsArtenDescription, SpartaxEinkunftsArtenName, SpartaxEinkunftsArt, SpartaxErklaerungsjahr, SpartaxAuftragskundeUtil } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto, SpartaxFormModelDtoUtil } from 'src/app/data/dto/spartax/spartax-form-model.dto';

@Component({
  selector: 'app-spartax-income-types',
  templateUrl: './spartax-income-types.component.html',
  styleUrls: ['./spartax-income-types.component.scss'],
})
export class SpartaxIncomeTypesComponent implements OnInit {
  @Input() 
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  public possibleEinkunftsarten = Object.keys(SpartaxEinkunftsArt);

  constructor(private alertController: AlertController) { }

  ngOnInit() { }

  formIsValid() {
    return this.ngForm.valid;
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  getDisplayNameOfEinukunftsArt(Einkunftsart: SpartaxEinkunftsArt | string) {
    return SpartaxEinkunftsArtenName.get(Einkunftsart);
  }
  
  getDisplayDescriptionOfEinukunftsArt(Einkunftsart: SpartaxEinkunftsArt | string) {
    return SpartaxEinkunftsArtenDescription.get(Einkunftsart);
  }

  valueIsSet(year: SpartaxErklaerungsjahr, value: SpartaxEinkunftsArt | string) {
    for (let einkunftsart of year.EinkunftsArten) {
      if (einkunftsart == value) {
        return true;
      }
    }
    return false;
  }

  async changeState(year: SpartaxErklaerungsjahr, value: SpartaxEinkunftsArt | string, kunde: SpartaxAuftragskunde) {
    if (!this.valueIsSet(year, value)) {
      year.EinkunftsArten.push(value as SpartaxEinkunftsArt);
      let einkunftsartExists = 0;
      for (let erklaerungsjahr of kunde.Erklaerungsjahre) {
        for (let einkunftsart of erklaerungsjahr.EinkunftsArten) {
          if (einkunftsart == value) {
            einkunftsartExists++;
          }
        }
      }

      if (einkunftsartExists <= 1) {
        if(year.Jahr != kunde.Erklaerungsjahre[kunde.Erklaerungsjahre.length - 1].Jahr){
          const overtakeAction = () => {
            for (let erklaerungsjahr of kunde.Erklaerungsjahre) {
              if (erklaerungsjahr.Jahr > year.Jahr) {
                erklaerungsjahr.EinkunftsArten.push(value as SpartaxEinkunftsArt);
              }
            }
          }
          await this.showOvertakePopup(overtakeAction);
        }
      }
    } else {
      const index = year.EinkunftsArten.indexOf(value as SpartaxEinkunftsArt, 0);
      year.EinkunftsArten.splice(index, 1);
    }
  }

  async showOvertakePopup(overtakeAction: () => void) {
      const alert = await this.alertController.create({
        subHeader: 'Soll der gewählte Wert für die Folgejahre übernommen werden?',
        buttons: [
          {
            text: 'Nein',
            role: 'cancel',
          },
          {
            text: 'Ja',
            role: 'confirm',
            handler: overtakeAction
          },
        ],
      });

      await alert.present();
      await alert.onDidDismiss();
  }

  showUnterbrechungField(year: SpartaxErklaerungsjahr) {
    return this.valueIsSet(year, SpartaxEinkunftsArt.dienstverhaeltnis);
  }

  showBeteiligterField(year: SpartaxErklaerungsjahr) {
    return this.valueIsSet(year, SpartaxEinkunftsArt.betrieb) || this.valueIsSet(year, SpartaxEinkunftsArt.vermietung);
  }

  get erklaerungsjahre() {
    return from(this.model.auftragskunden).selectMany(x => x.Erklaerungsjahre).select(x => x.Jahr).distinct().orderBy(x => x).toArray();
  }

  showDienstverhaeltnisUnterbrechungForPerson(auftragskunde: SpartaxAuftragskunde) {
    return from(auftragskunde.Erklaerungsjahre).any(x => x.EinkunftsArten.includes(SpartaxEinkunftsArt.dienstverhaeltnis));
  }

  showBetriebsOderVermietungsGemeinschaftForPerson(auftragskunde: SpartaxAuftragskunde) {
    return from(auftragskunde.Erklaerungsjahre).any(x => 
      x.EinkunftsArten.includes(SpartaxEinkunftsArt.betrieb) || x.EinkunftsArten.includes(SpartaxEinkunftsArt.vermietung));
  }

  showYearForDienstverhaeltnis(year: SpartaxErklaerungsjahr) {
    return from(year.EinkunftsArten).any(x => x == SpartaxEinkunftsArt.dienstverhaeltnis);
  }

  showYearForGesellschaft(year: SpartaxErklaerungsjahr) {
    return from(year.EinkunftsArten).any(x => x == SpartaxEinkunftsArt.betrieb || x == SpartaxEinkunftsArt.vermietung);
  }

  showDienstverhaeltnisDetails(auftragskunde: SpartaxAuftragskunde) {
    return auftragskunde.Unterbrechung || false;
  }

  changeStateUnterbrechung(auftragskunde: SpartaxAuftragskunde){
    auftragskunde.Unterbrechung = !auftragskunde.Unterbrechung;
  }

  changeStateBoolean(year: SpartaxErklaerungsjahr, prop: string,
    kunde: SpartaxAuftragskunde) {
      year[prop] = !year[prop];

      if(year[prop]){
        const numberOfAlreadySetYears = from(kunde.Erklaerungsjahre).count(x => x[prop]);
        if(numberOfAlreadySetYears <= 1){
          const overtakeAction = () => {
            for (let erklaerungsjahr of kunde.Erklaerungsjahre) {
              if (erklaerungsjahr.Jahr > year.Jahr) {
                erklaerungsjahr[prop] = year[prop];
              }
            }
          }
          this.showOvertakePopup(overtakeAction);
        }
      }
  }

  valueIsSetSubquestions(value: any){
    return !!value;
  }

  getGridSize() {
    return this.model.auftragskunden.length > 5 ? 1 : 2;
  }

  isChild(auftragskunde: SpartaxAuftragskunde){
    return auftragskunde.AuftragsKundeArt == SpartaxAuftragskundeArt.kind;
  }

  getIncomeRelevantErklaerungsjahre(auftragskunde: SpartaxAuftragskunde) {
    return SpartaxAuftragskundeUtil.getIncomeRelevantErklaerungsjahre(auftragskunde);
  }

  isPersonYearVisible(einkunfstart: SpartaxEinkunftsArt | string, auftragskunde: SpartaxAuftragskunde, jahr: number) {
    let relevantByYear = true;
    if(auftragskunde.Kunde && auftragskunde.Kunde.VerstorbenAm) {
      relevantByYear = jahr <= moment(auftragskunde.Kunde.VerstorbenAm).year();
    }
    
    const relevantByEinkunftsart = auftragskunde.AuftragsKundeArt != SpartaxAuftragskundeArt.kind || einkunfstart == SpartaxEinkunftsArt.pension || 
      (einkunfstart == SpartaxEinkunftsArt.dienstverhaeltnis && SpartaxFormModelDtoUtil.getKindForAuftragskunde(this.model, auftragskunde)?.HatInAusbildungGearbeitet);

    return relevantByYear && relevantByEinkunftsart;
  }

  isPersonVisible(einkunfstart: SpartaxEinkunftsArt | string, auftragskunde: SpartaxAuftragskunde) {
    for(let jahr of this.erklaerungsjahre) {
      if(this.isPersonYearVisible(einkunfstart, auftragskunde, jahr)){
        return true;
      }
    }
    return false;
  }
}
