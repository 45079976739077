import * as moment from 'moment';
import { v1 as generateGUID } from 'uuid';
import { SpartaxDocument } from "./spartax-document.dto";
import { SpartaxPartner } from './spartax-partner.dto';
import { SpartaxUser } from './spartax-user.dto';
import { from } from 'linq-to-typescript';

//WARNING - Types MUST be kept in Sync between the Spartax Azure Function Project and with the ones in Beeexpert manually
export type SpartaxKunde = {
    ListItemId?: number;
    FormModelId?: string;
    ListItemIdPartner?: number;
    FormModelIdPartner?: string;
    ListItemIdVater?: number;
    FormModelIdVater?: string;
    ListItemIdMutter?: number;
    FormModelIdMutter?: string;
    ListItemIdsKinder?: number[];
    FormModelIdsKinder?: string[];
    TitelVorgestellt?: string;
    Vorname?: string;
    Nachname?: string;
    TitelNachgestellt?: string;
    Geschlecht?: 'Männlich' | 'Weiblich' | 'Divers';
    SVNummer?: number;
    Geburtstag?: Date;
    Telefon1?: string;
    Telefon2?: string;
    Telefon3?: string;
    Adresse?: string;
    PLZ?: string;
    Ort?: string;
    Email?: string;
    Bankname?: string;
    IBAN?: string;
    BIC?: string;
    Notiz?: string;
    Steuernummer?: string;
    Behinderungen?: SpartaxBehinderung[];
    BehinderungMitGutachten?: boolean;
    Behindertenpass?: boolean;
    Parkausweis?: boolean;
    ErhoehteFamilienBeihilfe?: boolean; //This field is for everything except step "children" in questionnaire
    Pflegegeldbezug?: boolean;
    AusweisArt?: string;
    Ausweisnummer?: string;
    Ausstellungsbehoerde?: string;
    AusstellungsdatumAusweis?: Date;
    Verstorben?: boolean;
    VerstorbenAm?: Date;
    procedureDiscussed?: boolean;
    GemeinsamerHaushalt?: boolean;
    Partner?: SpartaxPartner;
    Dokumente?: SpartaxDocument[];
    SevDeskId?: string;
    SevDeskKundennummer?: string;
    SevDeskAdditionalIds?: {
        AddressId?: string;
        CommunicationWayPhoneId?: string;
        CommunicationWayEmailId?: string;
    }
    SevDeskDefaultDiscountAmmount?: number;
    SevDeskDefaultDiscountPercentage?: boolean;
    SevDeskIban?: string;
    OldId?: number;

    //JSON Fields in SharePoint and string representitives ("Info" Fields)
    AdditionalData?: string;
    Familienstaende?: SpartaxFamilienstand[];
    Kinder?: SpartaxKind[];
    KinderDetail?: SpartaxKindDetail[];
    BehinderungDetail?: SpartaxBehinderungDetail[];
    Jahresloehne?: any;
    Wohnsitze?: SpartaxWohnsitz[];
    
    Author?: SpartaxUser;
    Editor?: SpartaxUser;
};

export abstract class SpartaxKundeUtil {
    public static createNewKunde(): SpartaxKunde {
        const kunde: SpartaxKunde = {};
        kunde.FormModelId = generateGUID();
        kunde.Dokumente = [];
        kunde.FormModelIdsKinder = [];
        kunde.ListItemIdsKinder = [];
        kunde.Behinderungen = [];
        kunde.Familienstaende = [];
        kunde.KinderDetail = [];
        kunde.BehinderungDetail = [];
        kunde.Wohnsitze = [];
        return kunde;
    }

    public static getAnredeOfKunde(kunde: SpartaxKunde): string {
        if(kunde.Geschlecht && kunde.Geschlecht === 'Männlich') {
            return 'Herr';
        } else if(kunde.Geschlecht && kunde.Geschlecht === 'Weiblich') {
            return 'Frau';
        } else {
            return '';
        }
    }

    public static getBriefanredeOfKunde(kunde: SpartaxKunde): string {
        if(kunde.Geschlecht && kunde.Geschlecht === 'Männlich') {
            return 'Sehr geehrter Herr';
        } else if(kunde.Geschlecht && kunde.Geschlecht === 'Weiblich') {
            return 'Sehr geehrte Frau';
        } else {
            return '';
        }
    }

    public static getVollstaendigerNameOfKundeWithAnrede(kunde: SpartaxKunde): string {
        return (this.getAnredeOfKunde(kunde) + ' ' + this.getVollstaendigerNameOfKunde(kunde)).trim();
    }

    public static getVollstaendigerNameOfKunde(kunde: SpartaxKunde, withCross = false): string {
        let name = (kunde.TitelVorgestellt && kunde.TitelVorgestellt.length > 0 ? kunde.TitelVorgestellt : '')
            + (kunde.Vorname && kunde.Vorname.length > 0 ? ' ' + kunde.Vorname : '')
            + (kunde.Nachname && kunde.Nachname.length > 0 ? ' ' + kunde.Nachname : '')
            + (kunde.TitelNachgestellt && kunde.TitelNachgestellt.length > 0 ? ' ' + kunde.TitelNachgestellt : '')
            + (withCross && kunde.Verstorben ? ' ✝' : '');

        return name.trim();
    }

    public static getKontaktdatenOfKunde(kunde: SpartaxKunde): string {
        let contactInfo = [];
        if (kunde.Telefon1 && kunde.Telefon1.length > 0) {
            contactInfo.push(kunde.Telefon1);
        }
        if (kunde.Telefon2 && kunde.Telefon2.length > 0) {
            contactInfo.push(kunde.Telefon2);
        }
        if (kunde.Email && kunde.Email.length > 0) {
            contactInfo.push(kunde.Email);
        }
        return contactInfo.join(', ');
    }

    public static getAnzeigenameMitKontaktdaten(kunde: SpartaxKunde): string {
        let kontaktdaten = this.getKontaktdatenOfKunde(kunde);
        return this.getAnzeigename(kunde) + (kontaktdaten && kontaktdaten.length > 0 ? ', ' + kontaktdaten : '');        
    }

    public static getAnschrift(kunde: SpartaxKunde): string {
        let town = (kunde.PLZ && kunde.PLZ.length > 0 ? kunde.PLZ : '')
            + (kunde.Ort && kunde.Ort.length > 0 ? ' ' + kunde.Ort : '');

        town = town.trim();

        return (kunde.Adresse && kunde.Adresse.length > 0 ? kunde.Adresse : '') + (town.length > 0 ? ', ' + town : '');
    }
    
    public static getAnzeigename(kunde: SpartaxKunde): string {
        const anschrift = this.getAnschrift(kunde);
        return this.getVollstaendigerNameOfKunde(kunde) + 
            (anschrift && anschrift.length > 0 ? ', ' + anschrift : '') +
            (kunde.Geburtstag ? ', ' + moment(kunde.Geburtstag).format('DD.MM.YYYY') : '') +
            (kunde.Verstorben ? ', ✝' : '');
    }

    public static getKundenRabattInfo(kunde: SpartaxKunde): string {
        if(kunde.SevDeskDefaultDiscountAmmount > 0) {
            return `${kunde.SevDeskDefaultDiscountAmmount} ${kunde.SevDeskDefaultDiscountPercentage ? '%' : '€'}`;
        } else {
            return '-';
        }        
    }

    public static getBehinderungInfo(kunde: SpartaxKunde): string {
        let info = '';
        if(kunde.BehinderungMitGutachten) {
            info += 'Behinderung mit Gutachten';
        }

        if(kunde.Behindertenpass) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'Behindertenpass';
        }

        if(kunde.Parkausweis) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'Parkausweis';
        }

        if(kunde.ErhoehteFamilienBeihilfe) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'erhöhte Familienbeihilfe';
        }

        if(kunde.Pflegegeldbezug) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'Pflegegeldbezug';
        }

        return info;
    }

    public static getKundendokumenteServerRelativeUrl(kunde: SpartaxKunde, spWebServerRelativeUrl: string): string {
        if(kunde.ListItemId) {
            return `${spWebServerRelativeUrl}/kundendokumente/kundendokumente ${kunde.ListItemId}`;
        } else {
            return null;
        }
    }
        
    public static updateKundePropsFromAnother(sourceKunde: SpartaxKunde, targetKunde: SpartaxKunde): void {
        targetKunde.FormModelId = sourceKunde.FormModelId;
        targetKunde.ListItemId = sourceKunde.ListItemId;
        targetKunde.TitelVorgestellt = sourceKunde.TitelVorgestellt;
        targetKunde.Vorname = sourceKunde.Vorname;
        targetKunde.Nachname = sourceKunde.Nachname;
        targetKunde.TitelNachgestellt = sourceKunde.TitelNachgestellt;
        targetKunde.Geschlecht = sourceKunde.Geschlecht;
        targetKunde.SVNummer = sourceKunde.SVNummer;
        targetKunde.Geburtstag = sourceKunde.Geburtstag;
        targetKunde.Telefon1 = sourceKunde.Telefon1;
        targetKunde.Telefon2 = sourceKunde.Telefon2;
        targetKunde.Adresse = sourceKunde.Adresse;
        targetKunde.PLZ = sourceKunde.PLZ;
        targetKunde.Ort = sourceKunde.Ort;
        targetKunde.Email = sourceKunde.Email;
        targetKunde.Bankname = sourceKunde.Bankname;
        targetKunde.IBAN = sourceKunde.IBAN;
        targetKunde.BIC = sourceKunde.BIC;
        targetKunde.Notiz = sourceKunde.Notiz;
        targetKunde.Steuernummer = sourceKunde.Steuernummer;
        targetKunde.AusweisArt = sourceKunde.AusweisArt;
        targetKunde.Ausweisnummer = sourceKunde.Ausweisnummer;
        targetKunde.Ausstellungsbehoerde = sourceKunde.Ausstellungsbehoerde;
        targetKunde.AusstellungsdatumAusweis = sourceKunde.AusstellungsdatumAusweis;
        targetKunde.GemeinsamerHaushalt = sourceKunde.GemeinsamerHaushalt;
        targetKunde.Verstorben = sourceKunde.Verstorben;
        targetKunde.VerstorbenAm = sourceKunde.VerstorbenAm;
        targetKunde.procedureDiscussed = sourceKunde.procedureDiscussed;
        targetKunde.BehinderungMitGutachten = sourceKunde.BehinderungMitGutachten;
        targetKunde.Behindertenpass = sourceKunde.Behindertenpass;
        targetKunde.Parkausweis = sourceKunde.Parkausweis;
        targetKunde.ErhoehteFamilienBeihilfe = sourceKunde.ErhoehteFamilienBeihilfe;
        targetKunde.Pflegegeldbezug = sourceKunde.Pflegegeldbezug;
        targetKunde.AdditionalData = sourceKunde.AdditionalData;
        targetKunde.Familienstaende = sourceKunde.Familienstaende;
        targetKunde.Kinder = sourceKunde.Kinder;
        targetKunde.KinderDetail = sourceKunde.KinderDetail;
        targetKunde.BehinderungDetail = sourceKunde.BehinderungDetail;
        targetKunde.Jahresloehne = sourceKunde.Jahresloehne;
        targetKunde.Wohnsitze = sourceKunde.Wohnsitze;
        targetKunde.Partner = sourceKunde.Partner;
        targetKunde.Dokumente = sourceKunde.Dokumente;
    }

    public static stripKundeOfSensitiveData(kunde: SpartaxKunde) {
        if(kunde) {
            delete kunde.SVNummer;
            delete kunde.Bankname;
            delete kunde.IBAN;
            delete kunde.BIC;
            delete kunde.Steuernummer;
            delete kunde.Behinderungen;
            delete kunde.BehinderungMitGutachten;
            delete kunde.Behindertenpass;
            delete kunde.Parkausweis;
            delete kunde.ErhoehteFamilienBeihilfe;
            delete kunde.Pflegegeldbezug;
            delete kunde.AusweisArt;
            delete kunde.Ausweisnummer;
            delete kunde.Ausstellungsbehoerde;
            delete kunde.AusstellungsdatumAusweis;
            delete kunde.Verstorben;
            delete kunde.VerstorbenAm;
            delete kunde.GemeinsamerHaushalt;
            delete kunde.Dokumente;
            delete kunde.SevDeskDefaultDiscountAmmount;
            delete kunde.SevDeskDefaultDiscountPercentage;
            delete kunde.SevDeskIban;
            delete kunde.AdditionalData;
            delete kunde.Familienstaende;
            delete kunde.Kinder;
            delete kunde.KinderDetail;
            delete kunde.BehinderungDetail;
            delete kunde.Jahresloehne;
            delete kunde.Wohnsitze;
        }
    }
}

export enum SpartaxEinkunftsArt {
    dienstverhaeltnis = 'dienstverhaeltnis',
    betrieb = 'betrieb',
    vermietung = 'vermietung',
    kapitalvermoegen = 'kapitalvermoegen',
    pension = 'pension'
}

export enum SpartaxBehinderung {
    behinderungTyp1 = "behinderungTyp1",
    behinderungTyp2 = "behinderungTyp2",
    behinderungTyp3 = "behinderungTyp3",
    behinderungTyp4 = "behinderungTyp4",
    behinderungTyp5 = "behinderungTyp5",
    behinderungTyp6 = "behinderungTyp6",
}

export const SpartaxBehinderungenName = new Map<string, string>([
    [SpartaxBehinderung.behinderungTyp1, 'Diabetes, Zöliakie, Tuberkulose, Aids, Bluthochdruck, oder Leiden an Galle, Leber, Nieren, des Magen-/Darmtrakts (auch Unverträglichkeiten)'],
    [SpartaxBehinderung.behinderungTyp2, 'Gehörschädigung, Taubheit'],
    [SpartaxBehinderung.behinderungTyp3, 'Herzinfarkt, Schlaganfall, Herz-, Lungen- oder Krebserkrankung, Immunschwäche'],
    [SpartaxBehinderung.behinderungTyp4, 'Leiden am Bewegungsapparat: Arme und Beine inklusive Wirbelsäule und Gelenke (Schulter, Hand, Hüfte, Knie, Fuß)'],
    [SpartaxBehinderung.behinderungTyp5, 'Gangstörung, Schwindel, Epilepsie, Inkontinenz, Blindheit, Demenz'],
    [SpartaxBehinderung.behinderungTyp6, 'Psychische Erkrankung (zB Angststörung, Depression, Alkohol- /Drogen- /Mager- /Medikamentensucht), od. geistige Beeinträchtigung']
]);

export type SpartaxBehinderungDetailArt = 'GdB 25 % - 34 %' | 'GdB 35 % - 44 %' | 'GdB 45 % - 54 %' | 'GdB 55 % - 64 %' | 'GdB 65 % - 74 %' | 
    'GdB 75 % - 84 %' | 'GdB 85 % - 94 %' | 'GdB ab 95 %' | 'D1 Krankendiät' | 'D2 Krankendiät' | 'D3 Krankendiät' | 'Parkausweis' | 'Pflegegeldbezug';

export type SpartaxBehinderungDetail = {
    Art?: SpartaxBehinderungDetailArt;
    Ab?: Date;
    Bis?: Date;
};

export abstract class SpartaxBehinderungUtil {
    public static getDetailAnzeigename(behinderung: SpartaxBehinderungDetail): string {
        return behinderung.Art + ' (ab ' + moment(behinderung.Ab).format('DD.MM.YYYY') + ' - ' + 
            (behinderung.Bis ? moment(behinderung.Bis).format('DD.MM.YYYY') : 'dato') + ')';
    }

    public static getDetailAnzeigenameForMany(behinderungen: SpartaxBehinderungDetail[]): string {
        let behinderungInfo = '';
        if(typeof behinderungen !== 'undefined' && behinderungen !== null) {
            const behinderungenSorted = from(behinderungen).orderBy(x => moment(x.Ab).unix()).toArray();
            for(let i = 0; i < behinderungenSorted.length; i++) {
                if(i > 0) {
                    behinderungInfo += '\n';
                }

                behinderungInfo += SpartaxBehinderungUtil.getDetailAnzeigename(behinderungenSorted[i]);
            }
        }
        return behinderungInfo;
    }

    public static getHash(behinderung: SpartaxBehinderungDetail): string {
        return (behinderung.Art || '') + 
            moment(behinderung.Ab || new Date()).unix().toString() + 
            moment(behinderung.Bis || new Date()).unix().toString();
    }
}

export type SpartaxKindDetailArt = 'FB' | 'FB erhöht' | 'Pflegegeldbezug' | 'GdB 25 % - 34 %' | 'GdB 35 % - 44 %' | 'GdB 45 % - 54 %' | 'D1 Krankendiät' |
    'D2 Krankendiät' | 'D3 Krankendiät' | 'Alimente bezahlt' | 'Auswärtige Berufsausbildung';

export type SpartaxKindDetail = {
    FormModelIdKind: string;
    Vorname: string;
    Art?: SpartaxKindDetailArt;
    Ab?: Date;
    Bis?: Date;
};

export abstract class SpartaxKindDetailUtil {
    public static getAnzeigename(kind: SpartaxKindDetail): string {
        return kind.Vorname + ' (' + kind.Art + ', ab ' + moment(kind.Ab).format('DD.MM.YYYY') + ' - ' +
            (kind.Bis ? moment(kind.Bis).format('DD.MM.YYYY') : 'dato') + ')';
    }

    public static getAnzeigenameForMany(kinder: SpartaxKindDetail[]): string {
        let kinderInfo = '';
        if(typeof kinder !== 'undefined' && kinder !== null) {
            for(let i = 0; i < kinder.length; i++) {
                if(i > 0) {
                    kinderInfo += '\n';
                }
    
                kinderInfo += SpartaxKindDetailUtil.getAnzeigename(kinder[i]);
            }
        }
        return kinderInfo;
    }

    public static getHash(kind: SpartaxKindDetail): string {
        return kind.FormModelIdKind + (kind.Art || '') + 
            moment(kind.Ab || new Date()).unix().toString() + 
            moment(kind.Bis || new Date()).unix().toString();
    }
}

export type SpartaxWohnsitz = {
    Adresse?: string;
    PLZ?: string;
    Ort?: string;
    Hauptwohnsitz?: boolean;
    Von?: Date;
    Bis?: Date;
};

export abstract class SpartaxWohnsitzUtil {
    public static getVollstaendigeAnschrift(wohnsitz: SpartaxWohnsitz): string { 
        let town = (wohnsitz.PLZ && wohnsitz.PLZ.length > 0 ? wohnsitz.PLZ : '')
            + (wohnsitz.Ort && wohnsitz.Ort.length > 0 ? ' ' + wohnsitz.Ort : '');

        town = town.trim();
        return wohnsitz.Adresse + ', ' + town;
    }

    public static getZeitraum(wohnsitz: SpartaxWohnsitz): string {
        return moment(wohnsitz.Von).format('DD.MM.YYYY') + ' - ' +
            (wohnsitz.Bis ? moment(wohnsitz.Bis).format('DD.MM.YYYY') : 'dato');
    }

    public static getAnzeigename(wohnsitz: SpartaxWohnsitz): string {
        return SpartaxWohnsitzUtil.getZeitraum(wohnsitz) + '; ' + SpartaxWohnsitzUtil.getVollstaendigeAnschrift(wohnsitz);
    }

    public static getAnzeigenameForMany(wohnsitze: SpartaxWohnsitz[]): string {
        let wohnsitzeInfo = '';
        if(typeof wohnsitze !== 'undefined' && wohnsitze !== null) {
            for(let i = 0; i < wohnsitze.length; i++) {
                if(i > 0) {
                    wohnsitzeInfo += '\n';
                }
          
                wohnsitzeInfo += SpartaxWohnsitzUtil.getZeitraum(wohnsitze[i]) + '; ' + SpartaxWohnsitzUtil.getVollstaendigeAnschrift(wohnsitze[i]);
            }
        }
        return wohnsitzeInfo;
    }
}

export enum SpartaxFamilienstandArt {
    verheiratet = 'verheiratet',
    lebensgemeinschaft = 'lebensgemeinschaft',
    ledig = 'ledig',
    dauerndgetrennt = 'dauerndgetrennt',
    geschieden = 'geschieden',
    verwitwet = 'verwitwet'
}

export const SpartaxFamilienstandArtenName = new Map<string, string>([
    [SpartaxFamilienstandArt.verheiratet, 'verheiratet'],
    [SpartaxFamilienstandArt.lebensgemeinschaft, 'Lebensgemeinschaft'],
    [SpartaxFamilienstandArt.ledig, 'ledig'],
    [SpartaxFamilienstandArt.dauerndgetrennt, 'dauernd getrennt'],
    [SpartaxFamilienstandArt.geschieden, 'geschieden'],
    [SpartaxFamilienstandArt.verwitwet, 'verwitwet']
]);

export type SpartaxFamilienstand = {
    Art?: SpartaxFamilienstandArt;
    Seit?: number;
    SeitDatum?: Date;
    VornamePartner?: string;
    Geschlecht?: 'Männlich' | 'Weiblich' | 'Divers';
    FamilienstaendePartner?: SpartaxFamilienstand[];
    SeitDatumNichtBekannt?: boolean;
};

export abstract class SpartaxFamilienstandUtil {
    public static getAnzeigename(familienstand: SpartaxFamilienstand): string {
        let name = '';

        name += (familienstand.Seit ? familienstand.Seit + ': ' : '') +
            (familienstand.Art ? SpartaxFamilienstandArtenName.get(familienstand.Art) : '') +
            (familienstand.VornamePartner ? ' mit ' + familienstand.VornamePartner : '');

        name = name.trim();
        return name;
    }

    public static getAnzeigenameForMany(familienstaende: SpartaxFamilienstand[]): string {
        let familienstandInfo = '';
        if(typeof familienstaende !== 'undefined' && familienstaende !== null) {
            for(let i = 0; i < familienstaende.length; i++) {
                if(i > 0) {
                    familienstandInfo += '\n';
                }

                familienstandInfo += SpartaxFamilienstandUtil.getAnzeigename(familienstaende[i]);
            }
        }
        return familienstandInfo;
    }
}

export type SpartaxKind = {
    KundeFormModelId?: string;
    Vorname?: string;
    AlimenteBezahlt?: boolean;
    AlimenteIchBezahlt?: boolean;
    AlimenteErhalten?: boolean;
    AlimenteIchErhalten?: boolean;
    FamilienBeihilfe?: boolean;
    ErhoehteFamilienBeihilfe?: boolean; //This field is for step "children" in questionnaire
    FamilienBeihilfeIchErhalten?: boolean;
    VaterIstExPartner?: boolean;
    MutterIstExPartner?: boolean;
    Vater?: string;
    Mutter?: string;
    InAusbildung?: boolean;
    HatInAusbildungGearbeitet?: boolean;
};

export abstract class SpartaxKindUtil {
    public static getKindInfo(kind: SpartaxKind): string {
        let info = '';
        if(kind.FamilienBeihilfe) {
            info += 'FB';
            if(kind.FamilienBeihilfeIchErhalten) {
                info += ' (ich)';
            } else {
                info += ' (Partner)';
            }
        }

        if(kind.ErhoehteFamilienBeihilfe) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'FB erhöht';
        }

        if(kind.AlimenteErhalten) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'A';

            if(kind.AlimenteIchErhalten) {
                info += ' (ich)';
            } else {
                info += ' (Partner)';
            }
        }

        if(kind.AlimenteBezahlt) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'A bez.';

            if(kind.AlimenteIchBezahlt) {
                info += ' (ich)';
            } else {
                info += ' (Partner)';
            }
        }

        if(kind.InAusbildung) {
            if(info.length > 0) {
                info += ', ';
            }
            info += 'in Ausbildung';
        }

        return info;
    }

    public static getAnzeigename(kind: SpartaxKind): string {
        let name = '';

        let alimenteErhaltenText = "";
        let alimenteBezahltText = "";
        let familienbeihilfeText = "";

        if (kind.AlimenteErhalten) {
            alimenteErhaltenText = kind.AlimenteIchErhalten ? ', Ich habe die Alimente erhalten' : ', Der Partner hat die Alimente erhalten'
        }

        if (kind.AlimenteBezahlt) {
            alimenteBezahltText = kind.AlimenteIchBezahlt ? ', Ich habe die Alimente bezahlt' : ', Der Partner hat die Alimente bezahlt'
        }

        if (kind.FamilienBeihilfe) {
            familienbeihilfeText = kind.FamilienBeihilfeIchErhalten ? ', Ich habe die Familienbeihilfe erhalten' : ', Der Partner hat die Familienbeihilfe erhalten'
        }

        name += kind.Vorname +
            ' (Vater: ' + kind.Vater +
            ', Mutter: ' + kind.Mutter +
            (kind.AlimenteBezahlt ? ', Alimente bezahlt: ja' : ', Alimente bezahlt: nein') +
            alimenteBezahltText +
            (kind.AlimenteErhalten ? ', Alimente erhalten: ja' : ', Alimente erhalten: nein') +
            alimenteErhaltenText +
            (kind.FamilienBeihilfe ? ', Familienbeihilfe: ja' : ', Familienbeihilfe: nein') +
            familienbeihilfeText +
            (kind.InAusbildung ? ', In Ausbildung: ja' : ', In Ausbildung: nein') + ')';

        name = name.trim();
        return name;
    }

    public static getAnzeigenameForMany(kinder: SpartaxKind[]) {
        let kinderInfo = '';
        if(typeof kinder !== 'undefined' && kinder !== null) {
            for(let i = 0; i < kinder.length; i++) {
                if(i > 0) {
                    kinderInfo += '\n';
                }

                kinderInfo += SpartaxKindUtil.getAnzeigename(kinder[i]);
            }
        }
        return kinderInfo;
    }
}
