import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor() { }

  public set(key: string, value: any, ttl?: number) {
    const now = new Date();
    const item = {
      value: value,
      expiry: ttl === undefined ? null : now.getTime() + ttl * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  public get(key: string): any {
    const item = JSON.parse(localStorage.getItem(key));
    if(item && item.expiry && item.expiry > new Date().getTime()) {
      return item.value;
    }
    return null;
  }
}
