import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SpartaxAuftrag, SpartaxAuftragIdDto } from "../dto/spartax/spartax-auftrag.dto";
import { SpartaxDocumentType } from "../dto/spartax/spartax-document.dto";
import { SpartaxFormModelDto } from "../dto/spartax/spartax-form-model.dto";
import { SpartaxFrage } from "../dto/spartax/spartax-frage.dto";
import { SpartaxKonfigJahreseinkunft } from "../dto/spartax/spartax-konfig.dto";
import { SpartaxKunde } from "../dto/spartax/spartax-kunde.dto";
import { CacheService } from "./cache.service";
import { SpartaxUserRole } from "../dto/spartax/spartax-user.dto";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class SpartaxService {
    constructor(
        private http: HttpClient,
        private cacheService: CacheService,
        private authService: AuthService,
    ) {}

    private readonly CACHE_KEY_QUESTIONS= 'spartax-questions';
    private readonly CACHE_KEY_JAHRSEINKUENFTE_CONFIG = 'spartax-jahreseinkuenfte-config';
    private readonly CACHE_KEY_USER_ROLE = 'spartax-user-role';
    private readonly CACHE_TTL_DEFAULT = 43200; // 12 hours

    createOrUpdateOrder(orderModel: SpartaxFormModelDto): Promise<SpartaxAuftragIdDto> {
        return this.http.post<SpartaxAuftragIdDto>(`${environment.apiUrl}/spartax/createOrUpdateOrder/`, orderModel).toPromise();
    }

    createOrUpdateCustomer(customer: SpartaxKunde): Promise<SpartaxKunde> {
        return this.http.post<SpartaxKunde>(`${environment.apiUrl}/spartax/createOrUpdateCustomer/`, customer).toPromise();
    }

    async getQuestions(nocache = false): Promise<SpartaxFrage[]> {
        let questions: SpartaxFrage[] = null;
        
        if(!nocache) {
            questions = this.cacheService.get(this.CACHE_KEY_QUESTIONS);
        }
        
        if(!questions) {
            questions = await this.http.get<SpartaxFrage[]>(`${environment.apiUrl}/spartax/questions?nocache=${nocache}`).toPromise();
            this.cacheService.set(this.CACHE_KEY_QUESTIONS, questions, this.CACHE_TTL_DEFAULT);
        }

        return questions;
    }

    async getJahreseinkuenfteConfig(nocache = false): Promise<SpartaxKonfigJahreseinkunft[]> {
        let jahreseinkuenfteConfig: SpartaxKonfigJahreseinkunft[] = null;

        if(!nocache) {
            jahreseinkuenfteConfig = this.cacheService.get(this.CACHE_KEY_JAHRSEINKUENFTE_CONFIG);
        }

        if(!jahreseinkuenfteConfig) {
            const response =  await this.http.get(`${environment.apiUrl}/spartax/config/jahreseinkuenfte?nocache=${nocache}`, {
                responseType: 'text',
            }).toPromise();
            jahreseinkuenfteConfig = JSON.parse(response);
            this.cacheService.set(this.CACHE_KEY_JAHRSEINKUENFTE_CONFIG, jahreseinkuenfteConfig, this.CACHE_TTL_DEFAULT);
        }

        return jahreseinkuenfteConfig;
    }

    async getCurrentUserRole(nocache = false): Promise<SpartaxUserRole> {
        let role: SpartaxUserRole = null;
        const cacheKey = `${this.CACHE_KEY_USER_ROLE}-${this.authService.currentUserValue.userId}`;

        if(!nocache) {
            role = this.cacheService.get(cacheKey);
        }

        if(!role) {
            const response = await this.http.get(`${environment.apiUrl}/spartax/config/role?nocache=${nocache}`, {
                responseType: 'text',
            }).toPromise();
            role = (response as string).replace(/"/g, '') as SpartaxUserRole;
            this.cacheService.set(cacheKey, role, this.CACHE_TTL_DEFAULT);
        }
        
        return role;
    }

    async getMultipleCustomers(formModelIds: string[]): Promise<SpartaxKunde[]> {
        return this.http.post<SpartaxKunde[]>(`${environment.apiUrl}/spartax/customers`, formModelIds).toPromise();
    }

    async getCustomer(formModelId: string): Promise<SpartaxKunde> {
        return this.http.get<SpartaxKunde>(`${environment.apiUrl}/spartax/customer/${formModelId}`).toPromise();
    }

    async getCustomerById(listItemId: number): Promise<SpartaxKunde> {
        return this.http.get<SpartaxKunde>(`${environment.apiUrl}/spartax/customerbyid/${listItemId}`).toPromise();
    }

    async getCustomerDocument(formModelId: string, documentType: SpartaxDocumentType): Promise<any> {
        return this.http.get(`${environment.apiUrl}/spartax/customerdocument/${formModelId}/${documentType}`, {responseType: 'blob'}).toPromise();
    }

    async createOrUpdateCustomerDocument(formModelId: string, documentType: SpartaxDocumentType, 
        fileName: string, file: File): Promise<any> {
            const formData = new FormData();
            formData.append('file', file, file.name);

            const fileNameEncoded = encodeURIComponent(fileName);

            return this.http.post(`${environment.apiUrl}/spartax/customerdocument/` +
                `${formModelId}/${documentType}/${fileNameEncoded}`, formData).toPromise();
    }

    async getOrderById(listItemId: number): Promise<SpartaxAuftrag> {
        return this.http.get<SpartaxAuftrag>(`${environment.apiUrl}/spartax/order/${listItemId}`).toPromise();
    }

    async queryCustomers(query: string): Promise<{key: string, value: string}[]> {
        return this.http.post<{key: string, value: string}[]>(`${environment.apiUrl}/spartax/customers/query`, { query: query }).toPromise();
    }
}