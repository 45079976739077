import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import * as e from 'express';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/data/service/auth.service';
import { ConsultantCategoryService } from 'src/app/data/service/consultant-category.service';
import { UserService } from 'src/app/data/service/user.service';

@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.scss'],
})
export class FilterComponentComponent implements OnInit {
  mainCategories = [];
  allCategories = [];
  selectedCatName = "Keine Einschränkungen";
  selectedSubCatName: string[] = [];
  isLoading = true;
  distanceText = "Keine örtlichen Einschränkungen";
  isOpnessActivated = false;
  navigationExtras: NavigationExtras = {
    queryParams: {
      backToPage: this.router.url
    }
  };
  private localVal;
  private localsubVal: string[];
  private currentUser;

  constructor(
    public nav: NavController,
    private router: Router,
    private userService: UserService,
    public authService: AuthService,
    private consultantCategoriesService: ConsultantCategoryService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.initializeData();
  }

  private async initializeData() {
    this.mainCategories = await this.consultantCategoriesService.getMainCategories().toPromise();
    this.allCategories = await this.consultantCategoriesService.getCategories().toPromise();

    this.localVal = await localStorage.getItem('local_field');
    const localSubfield = await localStorage.getItem('local_subfield');
    this.localsubVal = localSubfield ? JSON.parse(localSubfield) : [];

    if (this.authService.isLoggedIn()) {
      this.currentUser = await this.userService.getUser(this.authService.currentUserValue.userId).pipe(first()).toPromise();
      this.setUserPreferences();
    } else {
      this.setLocalPreferences();
    }

    this.setDistanceText();
    this.setOpenness();

    this.isLoading = false;
  }

  private setUserPreferences() {
    if (!this.currentUser.lastSelectedFieldId || this.currentUser.lastSelectedFieldId === -1) {
      this.selectedCatName = "Keine Einschränkungen";
    } else {
      this.selectedCatName = this.mainCategories.find(cat => cat.mainCategoryId == this.currentUser.lastSelectedFieldId)?.name || "Keine Einschränkungen";
      if (this.currentUser.lastSelectedSubFieldId && this.currentUser.lastSelectedSubFieldId.length > 0) {
        this.selectedSubCatName = this.currentUser.lastSelectedSubFieldId.map(subcatId => {
          return this.allCategories.find(subcat => subcat.categoryId == subcatId)?.name || "";
        }).filter(name => name);
      } else {
        this.selectedSubCatName = [];
      }
    }
  }

  private setLocalPreferences() {
    if (!this.localVal || this.localVal == "-1") {
      this.selectedCatName = "Keine Einschränkungen";
    } else {
      this.selectedCatName = this.mainCategories.find(cat => cat.mainCategoryId == this.localVal)?.name || "Keine Einschränkungen";
      if (this.currentUser.lastSelectedSubFieldId && this.currentUser.lastSelectedSubFieldId.length > 0) {
        this.selectedSubCatName = this.localsubVal.map(subcatId => {
          return this.allCategories.find(subcat => subcat.categoryId == subcatId)?.name || "";
        }).filter(name => name);
      } else {
        this.selectedSubCatName = [];
      }
    }
  }

  private setDistanceText() {
    const distance = this.selectedDistance();
    if (distance > -1 && this.currentLocation()) {
      this.distanceText = `Umkreis: ${Math.floor(distance)}km von ${this.currentLocation()}`;
    }
  }

  private setOpenness() {
    if (this.currentUser?.bfi) {
      this.isOpnessActivated = this.currentUser.bfi.bfiOToMaxIfSearching;
    } else {
      const tempBfi = this.userService.getTempBfi();
      this.isOpnessActivated = tempBfi?.bfiOToMaxIfSearching;
    }
  }

  changeCategory() {
    this.nav.navigateRoot('/field-filter', this.navigationExtras);
    this.modalCtrl.dismiss(null, 'cancel');
  }

  changeDistance() {
    this.nav.navigateRoot('/distance-filter', this.navigationExtras);
    this.modalCtrl.dismiss(null, 'cancel');
  }

  changeMatching() {
    this.nav.navigateRoot('/profile/bfi10', this.navigationExtras);
    this.modalCtrl.dismiss(null, 'cancel');
  }

  changeToMatchingPage() {
    if (this.router.url == '/matching') {
      window.location.reload();
    } else {
      this.nav.navigateRoot('/matching');
      this.modalCtrl.dismiss(null, 'cancel');
    }
  }

  isMatchingDone() {
    if (this.authService.isLoggedIn()) {
      return this.currentUser.bfi != null;
    } else {
      return localStorage.getItem('local_bfi10') != null;
    }
  }

  selectedDistance() {
    if (this.authService.isLoggedIn()) {
      return this.currentUser.lastSelectedDistance ?? -1;
    } else {
      return localStorage.getItem('local_distance') ?? -1;
    }
  }

  currentLocation() {
    if (this.authService.isLoggedIn()) {
      return this.currentUser.lastLocationName ?? "";
    }
    return localStorage.getItem('local_locationName') ?? "";
  }
}
