import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FootercompComponent } from './footercomp/footercomp.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Bfi10Component } from './bfi10/bfi10.component';
import { UserPopoverComponent } from './header/user-popover/user-popover.component';
import { SearchPopoverComponent } from './header/search-popover/search-popover.component';
import { PricingcompComponent } from './pricingcomp/pricingcomp.component';
import { EditProfilePopoverComponent } from './profile/edit-profile-popover/edit-profile-popover.component';
import { UserlistComponent } from './userlist/userlist.component';
import { BeeexpertUserPipe, UserRolePipe } from '../utils/custom-pipes-pipe';
import { SetUserRoleComponent } from './companyadmin/setuserrole/setuserrole.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InviteUserComponent } from './companyadmin/inviteuser/inviteuser.component';
import { WarningsComponent } from './warnings/warnings.component';
import { SelectTimeperiodComponent } from './select-timeperiod/select-timeperiod.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ShareFormComponent } from './share-form/share-form.component';
import { SpartaxIdentityComponent } from './forms/controls/forms-spartax-questionnaire/spartax-identity/spartax-identity.component';
import { SpartaxMaritialStatusComponent } from './forms/controls/forms-spartax-questionnaire/spartax-maritial-status/spartax-maritial-status.component';
import { SpartaxChildrenComponent } from './forms/controls/forms-spartax-questionnaire/spartax-children/spartax-children.component';
import { SpartaxIncomeTypesComponent } from './forms/controls/forms-spartax-questionnaire/spartax-income-types/spartax-income-types.component';
import { SpartaxYearlyIncomesComponent } from './forms/controls/forms-spartax-questionnaire/spartax-yearly-incomes/spartax-yearly-incomes.component';
import { SpartaxDisabilitiesComponent } from './forms/controls/forms-spartax-questionnaire/spartax-disabilities/spartax-disabilities.component';
import { SpartaxAdditionalQuestionsComponent } from './forms/controls/forms-spartax-questionnaire/spartax-additional-questions/spartax-additional-questions.component';
import { SpartaxPlaceOrderComponent } from './forms/controls/forms-spartax-questionnaire/spartax-place-order/spartax-place-order.component';
import { SpartaxOrderSuccessDialogComponent } from './forms/controls/forms-spartax-questionnaire/spartax-order-success-dialog/spartax-order-success-dialog.component';
import { ProfilecompComponent } from './profilecomp/profilecomp.component';
import { CanvasPopupComponent } from './canvas-popup/canvas-popup.component';
import { SpartaxBottomComponent } from './forms/spartax-bottom/spartax-bottom.component';
import { SpartaxHeaderComponent } from './spartax-header/spartax-header.component';
import { CreatenewcategoryPopupComponent } from './createnewcategory-popup/createnewcategory-popup.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FootercompComponent,
    PricingcompComponent,
    Bfi10Component,
    UserPopoverComponent,
    SearchPopoverComponent,
    EditProfilePopoverComponent,
    UserlistComponent,
    SetUserRoleComponent,
    InviteUserComponent,
    BeeexpertUserPipe,
    UserRolePipe,
    WarningsComponent,
    SelectTimeperiodComponent,
    ShowHidePasswordComponent,
    ImageUploadComponent,
    ShareFormComponent,
    SpartaxIdentityComponent,
    SpartaxMaritialStatusComponent,
    SpartaxChildrenComponent,
    SpartaxIncomeTypesComponent,
    SpartaxYearlyIncomesComponent,
    SpartaxDisabilitiesComponent,
    SpartaxAdditionalQuestionsComponent,
    SpartaxPlaceOrderComponent,
    SpartaxOrderSuccessDialogComponent,
    ProfilecompComponent,
    CanvasPopupComponent,
    SpartaxBottomComponent,
    SpartaxHeaderComponent,
    CreatenewcategoryPopupComponent
  ],
  imports: [CommonModule, IonicModule, FontAwesomeModule, RouterModule, FormsModule, ReactiveFormsModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    FootercompComponent,
    PricingcompComponent,
    Bfi10Component,
    UserPopoverComponent,
    SearchPopoverComponent,
    EditProfilePopoverComponent,
    UserlistComponent,
    SetUserRoleComponent,
    InviteUserComponent,
    BeeexpertUserPipe,
    UserRolePipe,
    WarningsComponent,
    SelectTimeperiodComponent,
    ShowHidePasswordComponent,
    ImageUploadComponent,
    ShareFormComponent,
    SpartaxIdentityComponent,
    SpartaxMaritialStatusComponent,
    SpartaxChildrenComponent,
    SpartaxIncomeTypesComponent,
    SpartaxYearlyIncomesComponent,
    SpartaxDisabilitiesComponent,
    SpartaxAdditionalQuestionsComponent,
    SpartaxPlaceOrderComponent,
    SpartaxOrderSuccessDialogComponent,
    ProfilecompComponent,
    CanvasPopupComponent,
    SpartaxBottomComponent,
    SpartaxHeaderComponent,
    CreatenewcategoryPopupComponent
  ],
})
export class SharedComponentsModule { }
