import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SpartaxAuftragskunde } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto, SpartaxFormModelDtoUtil } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKind, SpartaxKundeUtil } from 'src/app/data/dto/spartax/spartax-kunde.dto';

@Component({
  selector: 'app-spartax-children',
  templateUrl: './spartax-children.component.html',
  styleUrls: ['./spartax-children.component.scss'],
})
export class SpartaxChildrenComponent implements OnInit {
  @Input() 
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  public working: boolean = true;

  public get hauptkunde(): SpartaxAuftragskunde {
    return SpartaxFormModelDtoUtil.getHauptkundeAuftragskunde(this.model);
  }

  constructor() { }

  ngOnInit() { }

  formIsValid() {
    return this.ngForm.valid;
  }

  addChild() {
    const newKind: SpartaxKind = {};
    newKind.FamilienBeihilfeIchErhalten = false;
    newKind.AlimenteIchBezahlt = false;
    newKind.VaterIstExPartner = this.hauptkunde.Kunde.Geschlecht === 'Weiblich' && !this.hauptkunde.Kunde.Familienstaende[0].VornamePartner ? true : false;
    newKind.MutterIstExPartner = this.hauptkunde.Kunde.Geschlecht === 'Männlich' && !this.hauptkunde.Kunde.Familienstaende[0].VornamePartner ? true : false;

    this.model.kinder.push(newKind);
  }

  ngAfterViewInit() {
    this.working = false;
  }

  get possibleFathers(){
    let retval = new Map();
    if(this.hauptkunde.Kunde.Geschlecht === 'Männlich'){
      if(this.hauptkunde.Kunde.Familienstaende[0].VornamePartner) {
        retval.set("Ex-Partner von " + this.hauptkunde.Kunde.Familienstaende[0].VornamePartner, true);
      }      
      retval.set(this.hauptkunde.Kunde.Vorname, false);
    }else{
      retval.set("Ex-Partner von " + this.hauptkunde.Kunde.Vorname, true);
      if(this.hauptkunde.Kunde.Familienstaende[0].VornamePartner){
        retval.set(this.hauptkunde.Kunde.Familienstaende[0].VornamePartner, false);
      }
    }

    return retval;
  }

  get possibleMothers(){
    let retval = new Map();
    if(this.hauptkunde.Kunde.Geschlecht === 'Weiblich'){
      if(this.hauptkunde.Kunde.Familienstaende[0].VornamePartner) {
        retval.set("Ex-Partnerin von " + this.hauptkunde.Kunde.Familienstaende[0].VornamePartner, true);
      }
      retval.set(this.hauptkunde.Kunde.Vorname, false);
    }else{
      retval.set("Ex-Partnerin von " + this.hauptkunde.Kunde.Vorname, true);
      if(this.hauptkunde.Kunde.Familienstaende[0].VornamePartner){
        retval.set(this.hauptkunde.Kunde.Familienstaende[0].VornamePartner, false);
      }
    }

    return retval;
  }

  removeItem(array, idx) {
    this.working = true;
    let newarray = [];
    let currentIdx = 0;
    for (let val of array) {
      if (currentIdx != idx) {
        newarray.push(val);
      }
      currentIdx++;
    }
    this.model.kinder = newarray;
    setTimeout(() => {
      this.working = false;
    }, 50);
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  onFamilienbeihilfeChange(kind: SpartaxKind, value: boolean) {
    kind.FamilienBeihilfe = value;
    if(kind.FamilienBeihilfe) {
      if(kind.MutterIstExPartner || kind.VaterIstExPartner) {
        kind.FamilienBeihilfeIchErhalten = true;
      }
    } else {
      kind.FamilienBeihilfeIchErhalten = false;
      kind.ErhoehteFamilienBeihilfe = false;
    }
  }

  onAlimenteBezahltChange(kind: SpartaxKind, value: boolean) {
    kind.AlimenteIchBezahlt = value;
    if(kind.AlimenteBezahlt) {
      if(kind.MutterIstExPartner || kind.VaterIstExPartner) {
        kind.AlimenteIchBezahlt = true;
      }
    } else {
      kind.AlimenteIchBezahlt = false;
    }
  }

  onAlimenteErhaltenChange(kind: SpartaxKind, value: boolean) {
    kind.AlimenteErhalten = value;
    if(kind.AlimenteErhalten) {
      if(kind.MutterIstExPartner || kind.VaterIstExPartner) {
        kind.AlimenteIchErhalten = true;
      }
    } else {
      kind.AlimenteIchErhalten = false;
    }
  }
}
