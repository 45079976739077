import { from } from "linq-to-typescript";
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt, SpartaxAuftragskundeUtil } from "./spartax-auftragskunde.dto";
import { SpartaxDocument } from "./spartax-document.dto";
import { SpartaxKind, SpartaxKunde, SpartaxKundeUtil } from "./spartax-kunde.dto";
import { SpartaxPartner } from "./spartax-partner.dto";
import { SpartaxUser } from "./spartax-user.dto";
import { SpartaxFrage } from "./spartax-frage.dto";
import * as moment from "moment";

type SpartaxRechnung = {
    //Just a stub
    BetragNetto?: number;
    KundeFormModelId?: string;
}

//WARNING - Types MUST be kept in Sync between the Spartax Azure Function Project and with the ones in Beeexpert manually
export enum Auftragstatus {
    Neu = 'Neu',
    waD = 'waD',
    iB = 'iB',
    waB = 'waB',
    NFA = 'NFA',
    iA = 'iA',
    Ro = 'Ro',
    Rb = 'Rb',
    Abg = 'Abg',
}

export const AuftragStatusName = new Map<string, string>([
    [Auftragstatus.Neu, 'Neu'],
    [Auftragstatus.waD, 'Warten auf Dokumente'],
    [Auftragstatus.iB,  'In Bearbeitung'],
    [Auftragstatus.waB, 'Warten auf Bescheid'],
    [Auftragstatus.NFA, 'Nachricht Finanzonline'],
    [Auftragstatus.iA,  'In Abrechnung'],
    [Auftragstatus.Ro,  'Rechnungen offen'],
    [Auftragstatus.Rb,  'Rechnungen bezahlt'],
    [Auftragstatus.Abg, 'Abgeschlossen'],
]);

export const AuftragStatusReihenholge = new Map<string, number>([
    [Auftragstatus.Neu, 0],
    [Auftragstatus.waD, 1],
    [Auftragstatus.iB,  2],
    [Auftragstatus.waB, 3],
    [Auftragstatus.NFA, 4],
    [Auftragstatus.iA,  5],
    [Auftragstatus.Ro,  6],
    [Auftragstatus.Rb,  7],
    [Auftragstatus.Abg, 8],
]);

export const AuftragsStatusAutoNext = [
    Auftragstatus.Neu,
    Auftragstatus.waD,
    Auftragstatus.iB,
    Auftragstatus.NFA
];

export enum AuftragFehlerstatus {
    E000 = 'E000',
    E100 = 'E100',
}

export const AuftragFehlerStatusName = new Map<string, string>([
    [AuftragFehlerstatus.E000, 'E000 - Alles Ok'],
    [AuftragFehlerstatus.E100, 'E100 - Partnerermittlung fehlgeschlagen']
]);

export type SpartaxKommunikationEintrag = {
    Created?: Date;
    Author?: string;
    Note?: string;
};

export type SpartaxKonfigHonorare = {
    GueltigAb?: Date;
    BasisMin?: number;
    BasisMax?: number;
    SatzMin?: number;
    SatzMax?: number;
    Wiederaufnahme?: number;
    Pflichtveranlagung?: number;
    EAGewerbe?: number;
    EAVermietung?: number;
};

export type SpartaxAuftrag = {
    ListItemId?: number;
    Kunde?: SpartaxKunde;
    Auftragskunden?: SpartaxAuftragskunde[];
    Status?: Auftragstatus;
    StatusSeit?: Date;
    Partner?: SpartaxPartner;
    Berater?: SpartaxUser;
    Sachbearbeiter?: SpartaxUser;
    Info?: string;
    Finanzamt?: string;
    Rueckzahlung?: number;
    Fehlerstatus?: AuftragFehlerstatus;
    Fehlerbeschreibung?: string;
    Dokumente?: SpartaxDocument[];
    AdditionalData?: string;
    BeeexpertFormFilloutId?: string;
    BeeexpertFormId?: string;
    FristUnterlagenBereitstellen?: Date;
    FristBelegurgenz?: Date;
    Created?: Date;
    Modified?: Date;
    Author?: SpartaxUser;
    Editor?: SpartaxUser;
    Kommunikation?: SpartaxKommunikationEintrag[];
    Rechnungen?: SpartaxRechnung[];
    SevDeskInvoiceIds?: string[];
    SevDeskTotalInvoiceCreated?: boolean;
    SevDeskCreditNoteId?: string;
    FollowUpData?: SpartaxFolgeAuftragDto;
    //for future configuribility / override feature:
    Sockelbetrag?: number; 
    HonorarBasisMin?: number;
    HonorarBasisMax?: number;
    HonorarSatzMin?: number;
    HonorarSatzMax?: number;
    HonorarWiederaufnahme?: number;
    HonorarPflichtveranlagung?: number;
    HonorarEinnahmenAusgabenRechnungGewerbe?: number;
    HonorarEinnahmenAusgabenRechnungVermietung?: number;
};

export class SpartaxAuftragUtil {
    public static getStatusFormatted(status: Auftragstatus) {
        if(status) {
            return `${AuftragStatusName.get(status)} (${Auftragstatus[status]})`;
        } else {
            return '-';
        }
    }

    public static getStatusPercentComplete(status: Auftragstatus) {
        const allKeys = Object.keys(Auftragstatus);
        const currentKey = allKeys.indexOf(status);
        return (currentKey / (allKeys.length - 1));
    }

    public static getNextStatus(currentStatus: Auftragstatus) {
        const allKeys = Object.keys(Auftragstatus);
        const currentKey = allKeys.indexOf(currentStatus);
        return Auftragstatus[allKeys[currentKey + 1]];
    }

    public static getPreviousStatus(currentStatus: Auftragstatus) {
        const allKeys = Object.keys(Auftragstatus);
        const currentKey = allKeys.indexOf(currentStatus);
        return Auftragstatus[allKeys[currentKey - 1]];
    }

    public static isAuftragInMinimalStatus(currentStatus: Auftragstatus, minimumStatus: Auftragstatus) {
        const allKeys = Object.keys(Auftragstatus);
        const currentKey = allKeys.indexOf(currentStatus);
        const minimumKey = allKeys.indexOf(minimumStatus);
        return currentKey >= minimumKey;
    }

    public static getAuftragsdokumenteServerRelativeUrl(auftrag: SpartaxAuftrag, spWebServerRelativeUrl: string): string {
        if(auftrag.ListItemId) {
            return `${spWebServerRelativeUrl}/Auftragsdokumente/Auftragsdokumente ${auftrag.ListItemId}`;
        } else {
            return null;
        }
    }

    public static getRelevantHonorarConfig(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        if(honorarConfigs && honorarConfigs.length > 0) {
            const honorarConfigsSorted = from(honorarConfigs).orderByDescending(x => moment(x.GueltigAb).unix()).toArray();
            const honorarConfig = from(honorarConfigsSorted).firstOrDefault(x => moment(x.GueltigAb).toDate() <= moment(auftrag.Created).toDate());
            return honorarConfig;
        } else {
            return {BasisMin: 0, BasisMax: 0, SatzMin: 0, SatzMax: 0, Wiederaufnahme: 0, Pflichtveranlagung: 0, EAGewerbe: 0, EAVermietung: 0};
        }
    }

    public static getSockelbetrag(auftrag: SpartaxAuftrag) {
        return auftrag.Sockelbetrag || 0;
    }

    public static getHonorarBasisMin(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarBasisMin || relevantConfig.BasisMin ||  0;
    }

    public static getHonorarBasisMax(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarBasisMax || relevantConfig.BasisMax || 0;
    }

    public static getHonorarBasisSatzMin(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarSatzMin || relevantConfig.SatzMin || 0;
    }

    public static getHonorarBasisSatzMax(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarSatzMax || relevantConfig.SatzMax || 0;
    }

    public static getHonorarBasisWiederaufnahme(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarWiederaufnahme || relevantConfig.Wiederaufnahme || 0;
    }

    public static getHonorarBasisPflichtveranlagung(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarPflichtveranlagung || relevantConfig.Pflichtveranlagung || 0;
    }

    public static getHonorarBasisEinnahmenAusgabenRechnungGewerbe(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarEinnahmenAusgabenRechnungGewerbe || relevantConfig.EAGewerbe || 0;
    }

    public static getHonorarBasisEinnahmenAusgabenRechnungVermietung(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        const relevantConfig = this.getRelevantHonorarConfig(auftrag, honorarConfigs);
        return auftrag.HonorarEinnahmenAusgabenRechnungVermietung || relevantConfig.EAVermietung || 0;
    }

    public static getTotalRueckzahlungen(auftrag: SpartaxAuftrag) {
        return from(auftrag.Auftragskunden).sum(x => SpartaxAuftragskundeUtil.getRueckzahlungen(x));
    }

    public static calculateRueckzahlungSockelbetrag(auftrag: SpartaxAuftrag) {
        const sockelbetrag = this.getSockelbetrag(auftrag);
        const rueckzahlung = this.getTotalRueckzahlungen(auftrag);
        return sockelbetrag < rueckzahlung ? sockelbetrag : rueckzahlung;
    }

    public static calculateHonorarSockelbetrag(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return this.calculateRueckzahlungSockelbetrag(auftrag) * this.getHonorarBasisSatzMax(auftrag, honorarConfigs) / 1.2;
    }

    public static calculateRueckzahlungAbzglSockelbetrag(auftrag: SpartaxAuftrag) {
        return this.getTotalRueckzahlungen(auftrag) - this.calculateRueckzahlungSockelbetrag(auftrag);
    }

    public static calculateHonorarSatzRest(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        if(this.calculateRueckzahlungAbzglSockelbetrag(auftrag) <= this.getHonorarBasisMin(auftrag, honorarConfigs)) {
            return this.getHonorarBasisSatzMax(auftrag, honorarConfigs);
        } else if (this.calculateRueckzahlungAbzglSockelbetrag(auftrag) >= this.getHonorarBasisMax(auftrag, honorarConfigs)) {
            return this.getHonorarBasisSatzMin(auftrag, honorarConfigs);
        } else {
            return this.getHonorarBasisSatzMax(auftrag, honorarConfigs) - 
                (this.calculateRueckzahlungAbzglSockelbetrag(auftrag) - this.getHonorarBasisMin(auftrag, honorarConfigs)) *
                (this.getHonorarBasisSatzMax(auftrag, honorarConfigs) - this.getHonorarBasisSatzMin(auftrag, honorarConfigs)) /
                (this.getHonorarBasisMax(auftrag, honorarConfigs) - this.getHonorarBasisMin(auftrag, honorarConfigs));
        }
    }

    public static calculateHonorarRest(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return this.calculateRueckzahlungAbzglSockelbetrag(auftrag) * this.calculateHonorarSatzRest(auftrag, honorarConfigs) / 1.2;
    }

    public static calculateHonorarRueckzahlungGesamt(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return this.calculateHonorarSockelbetrag(auftrag, honorarConfigs) + this.calculateHonorarRest(auftrag, honorarConfigs);
    }

    public static calculateMischSatz(auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return this.calculateHonorarRueckzahlungGesamt(auftrag, honorarConfigs) * 1.2 / this.getTotalRueckzahlungen(auftrag);
    }
    
    public static calculateAuftragHonorarBasis(auftrag: SpartaxAuftrag, fragen: SpartaxFrage[], honorarConfigs: SpartaxKonfigHonorare[]) {
        return from(auftrag.Auftragskunden)
            .sum(x => SpartaxAuftragskundeUtil.calculateHonorarBasis(x, fragen, auftrag, honorarConfigs));
    }

    public static calculateAuftragHonorar(auftrag: SpartaxAuftrag, fragen: SpartaxFrage[], honorarConfigs: SpartaxKonfigHonorare[]) {
        return from(auftrag.Auftragskunden)
            .sum(x => SpartaxAuftragskundeUtil.calculateHonorarGesamt(x, fragen, auftrag, honorarConfigs));
    }

    public static calculateAuftragRechnungsbetragVerrechnet(auftrag: SpartaxAuftrag) {
        if(auftrag.Rechnungen) {
            return from(auftrag.Rechnungen).sum(x => +x.BetragNetto);
        } else {
            return 0;
        }
    }

    public static calculateAuftragHonorarNichtVerrechnet(auftrag: SpartaxAuftrag, fragen: SpartaxFrage[], honorarConfigs: SpartaxKonfigHonorare[]) {
        return from(auftrag.Auftragskunden)
            .sum(x => SpartaxAuftragskundeUtil.calculateHonorarNichtVerrechnet(x, fragen, auftrag, honorarConfigs));
    }

    public static getJahre(auftrag: SpartaxAuftrag) {
        let jahre = '';
        for(const kunde of auftrag.Auftragskunden) {
            if(kunde.TeilDesAuftrags) {
                const jahreOfAuftragskunde = SpartaxAuftragskundeUtil.getJahre(kunde);
                if(jahreOfAuftragskunde) {
                    if(jahre) {
                        jahre += '\n';
                    }
                    jahre += SpartaxKundeUtil.getVollstaendigerNameOfKunde(kunde.Kunde, true) + ': ' + jahreOfAuftragskunde;
                }
            }
        }
        return jahre;
    }

    public static getKindForAuftragskunde(auftrag: SpartaxAuftrag, kindFormModelId: string): SpartaxKind { 
        return from(auftrag.Auftragskunden).selectMany(x => x.Kunde.Kinder).firstOrDefault(x => x.KundeFormModelId === kindFormModelId);
    }

    public static getAllReleventFragenOfAuftrag(auftrag: SpartaxAuftrag, fragen: SpartaxFrage[]) {
        const relevanteFragenIds: number[] = [];

        for(const auftragskunde of auftrag.Auftragskunden) {
            const relevantFragenOfKunde = SpartaxAuftragskundeUtil.getAllRelevantFragenOfAuftragskunde(auftragskunde, fragen);
            for(const relevantFrage of relevantFragenOfKunde) {
                if(!relevanteFragenIds.includes(relevantFrage.ListItemId)) {
                    relevanteFragenIds.push(relevantFrage.ListItemId);
                }
            }
        }

        const relevantFragen = from(fragen)
                                .where(x => relevanteFragenIds.includes(x.ListItemId))
                                .orderBy(x => x.Fragenkategorie.Reihenfolge)
                                .thenBy(x => x.Reihenfolge)
                                .toArray();

        return relevantFragen;
    }

    public static getBelegeNeededCountOfAuftrag(auftrag: SpartaxAuftrag, fragen: SpartaxFrage[], fragenkategorieId: number = null) {
        const relevanteFragen = this.getAllReleventFragenOfAuftrag(auftrag, fragen);
        let numberOfBelegeNeeded = 0;
        for(const auftragskunde of auftrag.Auftragskunden) {
            const relevantFragenIds = 
                from(SpartaxAuftragskundeUtil.getAllRelevantFragenOfAuftragskunde(auftragskunde, relevanteFragen))
                .select(x => x.ListItemId)
                .toArray();

            const questionIdsOfCategory = from(relevanteFragen)
                .where(x => x.Fragenkategorie.ListItemId == fragenkategorieId).select(x => x.ListItemId);

            numberOfBelegeNeeded += from(auftragskunde.Erklaerungsjahre)
                .selectMany(x => x.FragenAntworten)
                .where(x => !!x.Antwort && relevantFragenIds.includes(x.FrageListItemId) && 
                    (!fragenkategorieId || questionIdsOfCategory.contains(x.FrageListItemId)))
                .count();
        }

        return numberOfBelegeNeeded;
    }

    public static getBelegeReceivedCountOfAuftrag(auftrag: SpartaxAuftrag, fragen: SpartaxFrage[], fragenkategorieId: number = null) {
        const relevanteFragen = this.getAllReleventFragenOfAuftrag(auftrag, fragen);
        
        const questionIdsOfCategory = from(relevanteFragen)
                .where(x => x.Fragenkategorie.ListItemId == fragenkategorieId).select(x => x.ListItemId);

        const numberOfBelegeReceived = from(auftrag.Auftragskunden)
          .selectMany(x => x.Erklaerungsjahre)
            .where(x => !!x.FragenBelegeErhalten)
          .selectMany(x => x.FragenBelegeErhalten)
            .where(x => (!fragenkategorieId || questionIdsOfCategory.contains(x.FrageListItemId)) && !!x.BelegeErhaltenAm)
          .count();

        return numberOfBelegeReceived;
    }

    public static stripAuftragOfSensitiveData(auftrag: SpartaxAuftrag) {
        SpartaxKundeUtil.stripKundeOfSensitiveData(auftrag.Kunde);
        if(auftrag.Auftragskunden) {
            for(const auftragskunde of auftrag.Auftragskunden) {
                SpartaxAuftragskundeUtil.stripAuftragskundeFromSensitiveData(auftragskunde);
            }
        }
        delete auftrag.Info;
        delete auftrag.Finanzamt;
        delete auftrag.Fehlerstatus;
        delete auftrag.Fehlerbeschreibung;
        delete auftrag.Dokumente;
        delete auftrag.AdditionalData;
        delete auftrag.Kommunikation;
        delete auftrag.Kommunikation;
        delete auftrag.Rechnungen;
        delete auftrag.Sockelbetrag;
        delete auftrag.HonorarBasisMin;
        delete auftrag.HonorarBasisMax;
        delete auftrag.HonorarSatzMin;
        delete auftrag.HonorarSatzMax;
        delete auftrag.HonorarWiederaufnahme;
        delete auftrag.HonorarPflichtveranlagung;
        delete auftrag.HonorarEinnahmenAusgabenRechnungGewerbe;
        delete auftrag.HonorarEinnahmenAusgabenRechnungVermietung;
    }
}

export type SpartaxKundeIdDto = {
    FormModelId: string;
    ListItemId: number;
};

export type SpartaxAuftragIdDto = {
    ListItemId: number;
    KundenIds: SpartaxKundeIdDto[];
};

export type SpartaxFolgeAuftragKundeIdDto = {
    FormModelId: string;
    AuftragsKundeArt: SpartaxAuftragskundeArt;
};

export type SpartaxFolgeAuftragDto = {
    ListItemId: number;
    LastYearOfNewOrder: number;
    Kunden: SpartaxFolgeAuftragKundeIdDto[];
};
