import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { from } from 'linq-to-typescript';

import { first } from 'rxjs/operators';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt, SpartaxAuftragskundeUtil, SpartaxErklaerungsjahr, SpartaxErklaerungsjahrUtil, SpartaxSchaetzungsJahreseinkunftArt } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKonfigJahreseinkunft } from 'src/app/data/dto/spartax/spartax-konfig.dto';
import { SpartaxService } from 'src/app/data/service/spartax.service';

@Component({
  selector: 'app-spartax-yearly-incomes',
  templateUrl: './spartax-yearly-incomes.component.html',
  styleUrls: ['./spartax-yearly-incomes.component.scss'],
})
export class SpartaxYearlyIncomesComponent implements OnInit {
  @Input() 
  model: SpartaxFormModelDto;

  @Output() 
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  public readonly possibleEinkuenfte = Object.keys(SpartaxSchaetzungsJahreseinkunftArt);

  public working: boolean = true;
  public error: string;
  
  private nocache = true;
  private configJahreseinkuenfte: SpartaxKonfigJahreseinkunft[];

  constructor(
    private activatedRoute: ActivatedRoute, 
    private alertController: AlertController,
    private spartaxService: SpartaxService
  ) { }

  ngOnInit() { }

  formIsValid() {
    for(const ak of this.model.auftragskunden) {
      if(this.showIncomeTable(ak)) {
        for(const year of this.getIncomeRelevantErklaerungsjahre(ak)) {
          if(!year.SchaetzungJahreseinkunft) {
            return false;
          }
        }
      }
    }
    return true;
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }

    this.error = null;
    if(!this.formIsValid()) {
      this.error = 'Bitte geben Sie für jedes Jahr eine Schätzung der Jahreseinkünfte an.';
    }
  }

  isChild(auftragskunde: SpartaxAuftragskunde){
    return auftragskunde.AuftragsKundeArt == SpartaxAuftragskundeArt.kind;
  }

  async ngAfterViewInit(): Promise<void> {
    const queryParams = await this.activatedRoute.queryParams.pipe(first()).toPromise();
    this.nocache = (/true/i).test(queryParams.nocache);
    this.configJahreseinkuenfte = await this.spartaxService.getJahreseinkuenfteConfig(this.nocache);
    this.working = false;
  }

  isClickable(year: SpartaxErklaerungsjahr, value: SpartaxSchaetzungsJahreseinkunftArt) {
    return this.edit && (!year.SchaetzungJahreseinkunft || year.SchaetzungJahreseinkunft == value);
  }
  
  getSchaetzungJahreseinkunftDisplayName(year: SpartaxErklaerungsjahr, value: SpartaxSchaetzungsJahreseinkunftArt) {
    return SpartaxErklaerungsjahrUtil.getSchaetzungJahreseinkunftDisplayName(value, year.Jahr, this.configJahreseinkuenfte);
  }

  getSchaetzungJahreseinkunftTooltip(year: SpartaxErklaerungsjahr, value: SpartaxSchaetzungsJahreseinkunftArt) {
    const baseText = SpartaxErklaerungsjahrUtil.getSchaetzungJahreseinkunftDisplayName(value, year.Jahr, this.configJahreseinkuenfte, false, true);
    return `${baseText} bei ganzjährigem Bezug`;
  }

  async setActive(year: SpartaxErklaerungsjahr, value: SpartaxSchaetzungsJahreseinkunftArt, auftragskunde: SpartaxAuftragskunde) {
    if(year.SchaetzungJahreseinkunft === value) {
      year.SchaetzungJahreseinkunft = null;
    } else {
      year.SchaetzungJahreseinkunft = value;
      const indexOfYear = this.getIncomeRelevantErklaerungsjahre(auftragskunde).indexOf(year);
      const remainingYears = from(this.getIncomeRelevantErklaerungsjahre(auftragskunde)).skip(indexOfYear + 1).toArray();
      if(remainingYears.length > 0 && !from(remainingYears).any(x => !!x.SchaetzungJahreseinkunft)) {
        await this.showOvertakePopup(auftragskunde, year);
      }
    }
  }

  showIncomeTable(auftragskunde: SpartaxAuftragskunde) {
    return auftragskunde.AuftragsKundeArt != SpartaxAuftragskundeArt.kind;
  }

  getIncomeRelevantErklaerungsjahre(auftragskunde: SpartaxAuftragskunde) {
    return SpartaxAuftragskundeUtil.getIncomeRelevantErklaerungsjahre(auftragskunde);
  }
  
  async showOvertakePopup(auftragskunde: SpartaxAuftragskunde, year: SpartaxErklaerungsjahr) {
    const alert = await this.alertController.create({
      subHeader: 'Sollen die ausgewählten Jahres-Gesamt-Einkünfte für die Folgejahre übernommen werden?',
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
        },
        {
          text: 'Ja',
          role: 'confirm',
          handler: () => {
            const indexOfYear = this.getIncomeRelevantErklaerungsjahre(auftragskunde).indexOf(year);
            for(const nextYear of from(this.getIncomeRelevantErklaerungsjahre(auftragskunde)).skip(indexOfYear + 1)) {
              nextYear.SchaetzungJahreseinkunft = year.SchaetzungJahreseinkunft;
            }
          },
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
  }
}
