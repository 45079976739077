import { SpartaxAuftragskunde, SpartaxAuftragskundeArt, SpartaxAuftragskundeUtil } from "./spartax-auftragskunde.dto";
import { SpartaxKind, SpartaxKunde, SpartaxKundeUtil } from "./spartax-kunde.dto";

//WARNING - Types MUST be kept in Sync between the Spartax Azure Function Project and with the ones in Beeexpert manually
export type SpartaxFormModelDto = {
    id: string;
    formId: string;
    listItemId: number;
    editor: string;
    editorCompanyId: number;
    formCompanyId: number;
    stepperIndex: number;
    hideNotRelevantAdditionalQuestions: boolean;
    auftragskunden: SpartaxAuftragskunde[];
    kinder: SpartaxKind[];
    sonstigeFragenBeantwortet: number[];
    berater: string;
}

export abstract class SpartaxFormModelDtoUtil {
    public static initModel(model: SpartaxFormModelDto, mainKunde: SpartaxKunde): void {
        model.hideNotRelevantAdditionalQuestions = true;
        model.auftragskunden = [
            SpartaxAuftragskundeUtil.CreateAuftragskundeFromQuestionnaire(
                mainKunde, 
                SpartaxAuftragskundeArt.hauptkunde
            )
        ];
        model.sonstigeFragenBeantwortet = [];
        model.kinder = [];
        model.berater = model.editor;

        if(!mainKunde.Dokumente) {
            mainKunde.Dokumente = [];
        }

        if(!mainKunde.FormModelIdsKinder) {
            mainKunde.FormModelIdsKinder = [];
        }

        if(!mainKunde.ListItemIdsKinder) {  
            mainKunde.ListItemIdsKinder = [];
        }

        if(!mainKunde.Behinderungen) {
            mainKunde.Behinderungen = [];
        }

        if(!mainKunde.Familienstaende) {
            mainKunde.Familienstaende = [];
        }

        if(!mainKunde.KinderDetail) {
            mainKunde.KinderDetail = [];
        }

        if(!mainKunde.BehinderungDetail) {
            mainKunde.BehinderungDetail = [];
        }

        if(!mainKunde.Wohnsitze) {
            mainKunde.Wohnsitze = [];
        }
    }

    public static initPartner(model: SpartaxFormModelDto): SpartaxAuftragskunde {
        if(!model.auftragskunden.find(x => x.AuftragsKundeArt === SpartaxAuftragskundeArt.aktuellerPartner)) {
            const hauptkunde = this.getHauptkundeAuftragskunde(model);
            const newKunde = SpartaxKundeUtil.createNewKunde();
            newKunde.FormModelIdPartner = hauptkunde.Kunde.FormModelId;
            newKunde.Vorname = hauptkunde.Kunde.Familienstaende[0].VornamePartner;
            newKunde.Familienstaende = hauptkunde.Kunde.Familienstaende[0].FamilienstaendePartner;
            newKunde.Familienstaende[0].VornamePartner = hauptkunde.Kunde.Vorname;

            model.auftragskunden.push(
                SpartaxAuftragskundeUtil.CreateAuftragskundeFromQuestionnaire(
                    newKunde, 
                    SpartaxAuftragskundeArt.aktuellerPartner
                )
            );
        }

        return this.getPartnerAuftragskunde(model);
    }

    public static initKind(model: SpartaxFormModelDto, kind: SpartaxKind): SpartaxAuftragskunde {
        if(!model.auftragskunden.find(x => x.KundeFormModelId === kind.KundeFormModelId)) {
            const newKunde = SpartaxKundeUtil.createNewKunde();
            newKunde.Vorname = kind.Vorname;
            kind.KundeFormModelId = newKunde.FormModelId;

            model.auftragskunden.push(
                SpartaxAuftragskundeUtil.CreateAuftragskundeFromQuestionnaire(
                    newKunde, 
                    SpartaxAuftragskundeArt.kind,
                )
            );
        }

        return this.getAuftragskundeForKind(model, kind);
    }

    public static getHauptkundeAuftragskunde(model: SpartaxFormModelDto): SpartaxAuftragskunde {
        return model.auftragskunden?.find(x => x.AuftragsKundeArt === SpartaxAuftragskundeArt.hauptkunde);
    }

    public static getPartnerAuftragskunde(model: SpartaxFormModelDto): SpartaxAuftragskunde {
        return model.auftragskunden?.find(x => x.AuftragsKundeArt === SpartaxAuftragskundeArt.aktuellerPartner);
    }

    public static getKinderAuftragskunden(model: SpartaxFormModelDto): SpartaxAuftragskunde[] {
        return model.auftragskunden?.filter(x => x.AuftragsKundeArt === SpartaxAuftragskundeArt.kind);
    }

    public static getAuftragskundeForKind(model: SpartaxFormModelDto, kind: SpartaxKind): SpartaxAuftragskunde {
        return model.auftragskunden?.find(x => x.KundeFormModelId === kind.KundeFormModelId);
    }

    public static getKindForAuftragskunde(model: SpartaxFormModelDto, auftragskunde: SpartaxAuftragskunde): SpartaxKind {
        return model.kinder?.find(x => x.KundeFormModelId === auftragskunde.KundeFormModelId);
    }
}