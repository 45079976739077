import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/data/service/auth.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppService } from 'src/app/data/service/app.service';
import { RangeCustomEvent, RangeValue } from '@ionic/core';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/data/service/user.service';
import { ConsultantCategoryService } from 'src/app/data/service/consultant-category.service';
import { IonModal, ModalController, NavController } from '@ionic/angular';
import { FilterComponentComponent } from '../filter-component/filter-component.component';
import { CompanySubscriptionService } from 'src/app/data/service/company-subscription.service';
import { sub } from 'date-fns';
import { DomSanitizer } from '@angular/platform-browser';
import { Base64 } from 'js-base64';
import { UserRole } from 'src/app/data/entities';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isMaintenanceActive = false;
  presentingElement = null;
  showConsultantReminder = false;
  showCreditCardReminder = false;
  showYouAreBasicUserReminder = false;
  permiumEnd: number;
  showSubmenu = false;



  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    private modalCtrl: ModalController,
    private appService: AppService,
    private userService: UserService,
    private companySubscriptionService: CompanySubscriptionService,
    private nav: NavController,
    private companySubscription: CompanySubscriptionService,
    private sanitizer: DomSanitizer,
  ) { }



  async openModal() {
    const modal = await this.modalCtrl.create({
      component: FilterComponentComponent,
    });
    modal.present();


    const { data, role } = await modal.onWillDismiss();
  }



  async ngOnInit() {
    this.isMaintenanceActive = await this.appService.isMaintenanceActive();
    this.presentingElement = document.querySelector('.ion-page');

    try {
      if (this.authService.isLoggedIn()) {
        let user = await this.userService
          .getUser(this.authService.currentUserValue?.userId)
          .toPromise();

        if (user.company && (user.role == UserRole.admin || user.role == UserRole.companyAdmin)) {
          let subscriptions = await this.companySubscriptionService.getMyCompaniesSubscriptions().toPromise();
          if (subscriptions.length == 0 && user.consultantReminder && !user?.hasActiveLicense) {
            this.showConsultantReminder = true;
          }
          if (subscriptions.length == 1) {
            let paymentMeth = await this.companySubscription.getAvailablePaymentMethods().toPromise();


            if (subscriptions[0].subscriptionCode == "d"
              && subscriptions[0].lastPayment == null
              && paymentMeth.length == 0) {

              const datumMitSechsMonaten: Date = new Date(subscriptions[0].subscriptionStart);
              datumMitSechsMonaten.setMonth(datumMitSechsMonaten.getMonth() + 6);
              this.permiumEnd = this.countDaysTill(datumMitSechsMonaten);


              if (this.permiumEnd <= 0) {
                this.showYouAreBasicUserReminder = true;
              } else {
                this.showCreditCardReminder = true;
              }
            }
          }
        }

      }

    } catch (err) { }


  }

  async addNewPaymentMethod() {
    const formForPayment = await this.companySubscriptionService
      .getFormForAddingNewPaymentMethodRedirection()
      .toPromise();

    let nodehtml = document.createElement('div');
    nodehtml.innerHTML = <string>(
      this.sanitizer.bypassSecurityTrustHtml(Base64.decode(formForPayment.html))
    );

    document.getElementsByTagName('body')[0].appendChild(nodehtml);

    let nodeJS = document.createElement('script');
    nodeJS.textContent = formForPayment.js;
    nodeJS.type = 'text/javascript';
    nodeJS.async = true;
    nodeJS.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(nodeJS);
  }

  countDaysTill(zielDatum: Date): number {
    const heute: Date = new Date();
    const einTagInMillisekunden: number = 24 * 60 * 60 * 1000; // Anzahl Millisekunden in einem Tag

    // Setze die Uhrzeit auf Mitternacht, um nur das Datum zu berücksichtigen
    heute.setHours(0, 0, 0, 0);
    zielDatum.setHours(0, 0, 0, 0);

    // Berechne die Differenz in Tagen
    const differenzInTagen: number = Math.round((zielDatum.getTime() - heute.getTime()) / einTagInMillisekunden);

    return differenzInTagen;
  }

  isDateLongerThan3MonathsInThePast(datum: Date): boolean {
    const heute: Date = new Date();
    const vergangeneMonate: number = 3;
    const vergangeneGrenze: Date = new Date(heute.getFullYear(), heute.getMonth() - vergangeneMonate, heute.getDate());

    return datum < vergangeneGrenze;
  }

  getConsultant() {
    this.nav.navigateRoot('/success-profile-fill');
  }


  isMobile() {
    if (window.innerWidth > 770) {
      return false;
    }
    return true;
  }

  isTablet() {
    if (window.innerWidth > 770 && window.innerWidth < 992) {
      return true;
    }
    return false;
  }


  topFunction() {
    const element = document.getElementById('top');

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 250);
    }
  }


  get pathname() {
    return window.location.pathname;
  }

  beConsultantClicked() {

    location.href = 'https://www.beeexpert.at/berater-werden';
  }

  matchingDetailsClicked() {
    location.href = 'https://www.beeexpert.at';
  }

  contactClicked() {
    location.href = 'https://www.beeexpert.at/kontakt';
  }

  get showFullHeader() {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    if (localStorage.getItem('filter_finished')) {
      return true;
    }

    return false;
  }

}
